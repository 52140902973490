import React from 'react'

import { ScreenSizeContextProvider, PopupContextProvider } from 'contexts'

// All Global Provides that needed to wrap the App in here.
export const Providers: React.FC<any> = ({ children }) => {
  return (
    <ScreenSizeContextProvider>
      <PopupContextProvider>{children}</PopupContextProvider>
    </ScreenSizeContextProvider>
  )
}
