import { createContext, useCallback, useContext, useState } from 'react'

interface IPopupContext {
  isShowMain: boolean
  isShowSub: boolean
  handleIsShowMain: (_isShow?: boolean) => void
  handleIsShowSub: (_isShow?: boolean) => void
}

const initialState = { isShowMain: true, isShowSub: true, handleIsShowMain: () => null, handleIsShowSub: () => null }
const PopupContext = createContext<IPopupContext>(initialState)
export const usePopupContext = () => useContext(PopupContext)

export const PopupContextProvider: React.FC<any> = ({ children }) => {
  const [isShowMain, setIsShowMain] = useState<boolean>(() => localStorage.getItem('isShowMain') !== 'false')
  const [isShowSub, setIsShowSub] = useState<boolean>(() => localStorage.getItem('isShowSub') !== 'false')
  const handleIsShowMain = useCallback((_isShow?: boolean) => {
    setIsShowMain(_isShow ?? false)
    localStorage.setItem('isShowMain', _isShow ? 'true' : 'false')
  }, [])
  const handleIsShowSub = useCallback((_isShow?: boolean) => {
    setIsShowSub(_isShow ?? false)
    localStorage.setItem('isShowSub', _isShow ? 'true' : 'false')
  }, [])

  return <PopupContext.Provider value={{ isShowMain, isShowSub, handleIsShowMain, handleIsShowSub }}>{children}</PopupContext.Provider>
}
