import { useEffect } from 'react'

import { usePopupContext } from 'contexts'

export const usePopup = () => {
  const { handleIsShowMain, handleIsShowSub } = usePopupContext()

  useEffect(() => {
    const isShowMain = localStorage.getItem('isShowMain')
    const isShowSub = localStorage.getItem('isShowSub')
    if (isShowMain === 'false') handleIsShowMain(true)
    if (isShowSub === 'false') handleIsShowSub(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
