import { lazy } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

const Home = lazy(() => import('./pages/Home'))
const About = lazy(() => import('./pages/About'))
const ProjectDocuments = lazy(() => import('./pages/ProjectDocuments'))
const Environmental = lazy(() => import('./pages/Environmental'))
const Technology = lazy(() => import('./pages/Technology'))
const CommunityBenefits = lazy(() => import('./pages/CommunityBenefit'))
const Timeline = lazy(() => import('./pages/Timeline'))
const News = lazy(() => import('./pages/News'))
const NewsDetail = lazy(() => import('./pages/News/components/Detail'))
const ContactUs = lazy(() => import('./pages/ContactUs'))

const MainRoutes = () => {
  const location = useLocation()

  return (
    <Routes location={location} key={location.pathname}>
      <Route path={'/home'} element={<Home />} />
      <Route path={'/about'} element={<About />} />
      <Route path={'/project_documents'} element={<ProjectDocuments />} />
      <Route path={'/environmental'} element={<Environmental />} />
      <Route path={'/technology'} element={<Technology />} />
      <Route path={'/benefits'} element={<CommunityBenefits />} />
      <Route path={'/timeline'} element={<Timeline />} />
      <Route path={'/news'} element={<News />}>
        <Route path={':id'} element={<NewsDetail />} />
      </Route>
      <Route path={'/contact_us'} element={<ContactUs />} />

      <Route path={'/*'} element={<Navigate replace to="/home" />} />
    </Routes>
  )
}

export default MainRoutes
